import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = { 'Content-Type' : 'application/json' };

export default class ProgramApi {
    /**
     * class method getPrograms
     * @returns a promise of an array of all programs
     */
    async getPrograms() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/program`, {
            headers: headers
        }).then(response => response.json());
    }
}